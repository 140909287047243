/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from "react";
import Layout from "../Layout/MasterLayout/MasterLayout";
import Button from "../components/Button/Button";
import CarrouselText from "../components/CarouselText/CarouselText";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import BannerCarousel from "../components/BannerCarousel/BannerCarousel";
import SEO from "../components/SEO/SEO";

import imgMatriculas from "../assets/images/matriculas/matriculas.png";

import '../components/css/images.css'

export default function Matriculas() {
	let itensCarouselText = [
		{
			title: "Inscreva-se",
			description:
				"A realização da inscrição é uma das etapas do processo de ingresso na Escola de Aplicação Feevale. A possibilidade de realizar a inscrição on-line garante mais agilidade e comodidade, pois pode ser realizada no horário e local mais conveniente para as famílias. Basta preencher o formulário de inscrição e a Escola entrará em contato para agendar o dia e horário do bate-papo com a equipe pedagógica.",
		},
		{
			title: "Bate-papo com a Equipe Pedagógica e tour guiado pela Escola",
			description:
				"O bate-papo é um momento inicial de aproximação entre família e escola. É quando a Equipe Pedagógica apresenta a metodologia da Escola e a família esclarece as dúvidas. Neste momento, também é oferecido um tour guiado para que família e estudante conheçam as instalações e a estrutura do câmpus.",
		},
		{
			title: "Matrícula",
			description: `Após o bate-papo, a família receberá um e-mail com as instruções para a realização da matrícula on-line*.
                <p class="small font-italic text-white">
                * A matrícula somente será autorizada mediante a disponibilidade de vaga no Ciclo de Aprendizagem.
                </p>
                `,
		},
	];

	return (
		<Layout>
			<SEO title="Matrículas" lang="pt-br" />
			<MDBContainer>
				<MDBRow>
					<MDBCol
						col={12}
						md={6}
						className="order-1 order-sm-0 my-auto px-3 px-md-2 pb-4"
					>
						<h1
							className="pageTitle fgsecondary-gradient d-none d-md-block"
							style={{ fontSize: "3.7em" }}
						>
							venha para a escola de aplicação feevale
						</h1>
						<h1
							className="pageTitle fgsecondary-gradient d-block d-md-none"
							style={{ fontSize: "2.5em" }}
						>
							venha para a escola de aplicação feevale
						</h1>
					</MDBCol>
					<MDBCol
						col={12}
						md={6}
						className="py-5 text-justify order-0 order-sm-1 imagemTopo"
					>
						<img src={imgMatriculas} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<BannerCarousel
							bannerKey="58028DE1-19C1-4AD7-8B1E-A15648C57538"
							bannerLength="6"
						/>
					</MDBCol>
				</MDBRow>
				{/* <MDBRow>
					<MDBCol className="py-4">
						<Button
							type="comercial"
							to="http://www.feevale.br/rematriculaescola/Login/Externo"
							text="Faça sua rematrícula"
						/>
					</MDBCol>
				</MDBRow> */}
				<MDBRow className="pt-4">
					<MDBCol col={12} md={4} className="py-2 py-md-5 text-center">
						<h2
							className="fgsecondary-gradient font-italic"
							style={{ fontSize: "3em" }}
						>
							para ser estudante
						</h2>
						<p className="small mt-4">
							<span className="fgsecondary-gradient">Atenção!</span>
							<br />
							Estudantes menores de 18 anos deverão comparecer acompanhados do
							responsável legal.
						</p>
					</MDBCol>
					<MDBCol col={12} md={8}>
						<CarrouselText
							objetos={itensCarouselText}
							bgColor="bgsecondary-gradient"
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="pt-5">
						<h3 className="fgsecondary-gradient text-left">observações</h3>
						<ul style={{ listStyleType: "none" }}>
							<li>
								// Para os estudantes que efetuarem rematrícula, o valor é
								divido entre os meses do ano letivo, de acordo com a data do
								ingresso. No caso de estudantes novos, estes pagam a primeira
								parcela no ato da matrícula, sendo o valor do ano dividido entre
								os meses do ano letivo vigente, de acordo com a data de
								ingresso.
							</li>
							<li>
								// No caso de estudantes transferidos no decorrer do ano letivo,
								estes pagam a primeira parcela no ato da matrícula, sendo que o
								valor será calculado a partir dos dias letivos restantes do ano.
							</li>
							<li>
								// Para estudantes novos é cobrado o Serviço de Matrícula no
								valor de R$ 132,50.
							</li>
						</ul>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={6} className="py-2">
						<Button
							type="comercial"
							to="http://www.feevale.br/formularios/formulario/AjustaSessao?IdCurso=9157"
							text="Inscrições 2025"
						/>
					</MDBCol>
					<MDBCol col={12} md={6} className="py-2">
						<Button
							type="comercial"
							to="http://www.feevale.br/formularios/formulario/AjustaSessao?IdCurso=9157"
							text="Transferências"
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow>
				<MDBCol col={12} md={12} className="py-2">
						<Button
							type="comercial"
							to="https://editais.feevale.br/editais/edital-proen-n-062024-renovacao-bolsa-escola/"
							text="Bolsas de Estudos 2025"
						/>
					</MDBCol>
				</MDBRow>
				
				<MDBRow>
					<MDBCol className="pt-3">
						<h3 className="fgsecondary-gradient text-left">
							documentos para matrícula
						</h3>
						<ul style={{ listStyleType: "none" }}>
							<li>// Uma foto 3x4 do estudante.</li>
							<li>// Cópia da Certidão de Nascimento do estudante.</li>
							<li>
								// Cópia da Carteira de Identidade (RG) e do CPF do estudante
								(obrigatório para estudantes do Ensino Médio e Curso Técnico).
							</li>
							<li>
								// Cópia da Carteira de Identidade (RG) e do CPF do responsável
								pedagógico e do responsável financeiro.
							</li>
							<li>// Histórico Escolar Original (ler observações).</li>
							<li>// Atestado de Matrícula e Frequência originais.</li>
							<li>// Cópia do comprovante de residência com CEP.</li>
							<li>// Carteira de Vacinação.</li>
							<li className="text-uppercase font-weight-bold mt-3">atenção</li>
							<li>
								// Estudantes que ainda não concluíram o ano letivo, devem
								apresentar Atestado de Matrícula e Frequência, com a data de
								previsão de conclusão e entrega do Histórico Escolar.
							</li>
							<li>
								// Estudantes estrangeiros deverão apresentar também cópia
								traduzida por tradutor juramentado.
							</li>
							<li>
								// Estudantes maiores de idade, devem apresentar o Comprovante de Quitação com o Serviço Eleitoral (ultima eleição).
							</li>
							<li>
								// Estudantes maiores de idade, devem apresentar Certificado de Quitação com o Serviço Militar.
							</li>
						</ul>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgsecondary-gradient text-left">
							informações gerais
						</h3>
						<ul style={{ listStyleType: "none" }}>
							<li>
								// Estudantes menores de 18 anos devem comparecer acompanhados
								do responsável legal.
							</li>
							<li>
								// A inscrição não garante a vaga, é necessário concluir todo o
								processo de matrícula.
							</li>
							<li>
								// As matrículas para 2025, serão realizadas somente de forma
								on-line. Em virtude disso, os documentos devem ser
								digitalizados.
							</li>
						</ul>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgsecondary-gradient text-left">dúvidas?</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<p>
							// Fizemos uma live explicando mais sobre o funcionamento da
							escola, pra tirar suas dúvidas e refletirmos juntos sobre o futuro
							da educação.
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<div className="embed-responsive-16by9">
							<iframe
								className="border-0"
								width="100%"
								height="560"
								src="https://www.youtube.com/embed/Ag8WJnQnqZQ"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							></iframe>
						</div>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
