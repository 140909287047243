import React from "react";
import { Link } from "gatsby";

import {
	MDBCarousel,
	MDBCarouselInner,
	MDBCarouselItem,
	MDBCard,
	MDBCardBody,
	MDBCardTitle,
	MDBCardText,
	MDBContainer,
	MDBRow,
	MDBCol,
} from "mdbreact";

import PropTypes from "prop-types";

/**
 * 	Carousel de texto.
 * 	@param {array} bgColor - Recebe as classes de gradient sendo bgprimary-gradient, bgsecondary-gradient e bgterciary-gradient
 * 	@param {array} objetos - Recebe um array de objetos com os seguintes paramêtros
 *
 *  @param {string} text: "string",
 *  @param {string} title: "string",
 *  @param {string} description: "string"
 */
export default function CarouselImage({ objetos, bgColor }) {
	CarouselImage.propTypes = {
		objetos: PropTypes.array,
		bgColor: PropTypes.string,
	};

	if (objetos !== null) {
		return (
			<MDBCarousel
				activeItem={1}
				showControls={objetos?.length > 1 ? true : false}
				showIndicators={true}
				length={objetos?.length}
				className={bgColor + " h-100"}
			>
				<MDBCarouselInner>
					{objetos?.map((objeto, index) => (
						<>
							<MDBCarouselItem key={index} itemId={index + 1} className="p-0">
								{objeto?.link ? (
									<Link
										to={objeto.link}
										className="text-decoration-none"
										target={objeto?.link.indexOf("http") !== -1 && "_blank"}
									>
										<MDBCard className="m-0 px-md-3 pb-5 border-0 text-center bg-transparent">
											<MDBCardBody className="py-5 px-5">
												<MDBCardTitle
													className="text-white font-weight-bold"
													dangerouslySetInnerHTML={{ __html: objeto.title }}
												/>
												<MDBCardText
													className="text-white"
													dangerouslySetInnerHTML={{
														__html: objeto.description,
													}}
												/>
											</MDBCardBody>
										</MDBCard>
									</Link>
								) : (
									<MDBCard className="m-0 px-md-3 pb-5 border-0 text-center bg-transparent">
										<MDBCardBody className="py-5 px-5">
											<MDBCardTitle
												className="text-white font-weight-bold"
												dangerouslySetInnerHTML={{ __html: objeto.title }}
											/>
											<MDBCardText
												className="text-white"
												dangerouslySetInnerHTML={{
													__html: objeto.description,
												}}
											/>
										</MDBCardBody>
									</MDBCard>
								)}
							</MDBCarouselItem>
						</>
					))}
				</MDBCarouselInner>
			</MDBCarousel>
		);
	}
	return <></>;
}
